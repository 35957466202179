import useIsPageEmbedded from '@/hooks/use-is-page-embedded';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { INVALID_CHAT_ID } from '../config';

export const useChatOnStage = (): number => {
  const isEmbedded = useIsPageEmbedded();
  const location = useLocation();
  const queryStr = location.hash?.split(',')[0].substring(1);

  const isShownOnStage = useMemo(() => {
    if (!isEmbedded || !queryStr) {
      return INVALID_CHAT_ID;
    }
    const [type, id] = queryStr.split('=') || [];
    if (type !== 'chat') {
      return INVALID_CHAT_ID;
    }
    const parsedId = Number(id);
    return parsedId || INVALID_CHAT_ID;
  }, [isEmbedded, queryStr]);

  return isShownOnStage;
};

export default useChatOnStage;
