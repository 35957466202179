/* eslint-disable react/jsx-fragments */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Text, {
  ITextColors,
  ITextSizes,
  ITextMargins,
  ITextStyles,
} from '@/components/ui/content/Text';
import { IChatBoxParent, IChatBoxTypes } from '@/components/chat/ChatBox/types';
import ProfilePicture from '@/components/ui/ProfilePicture';
import {
  getImageUrlWithSize,
  isLiveEventOrganizer,
  prefixPicUrlIfNeeded,
} from '@/utils/helpers';
import {
  IChatMessageProps,
  IChatMessageSender,
  IPopupUser,
  PIN_MESSAGE_LEN_LIMIT,
} from './types';
import { getMessageTime } from './config';
import styles from './styles.module.scss';
import { IContentColors, IGeneralSizeTypes } from '@/types';
import { MoreVertical, Trash } from 'react-feather';
import { getCssVar } from '@/utils/cssVars';
import Dropdown from './DropDown';
import api from '@/api';
import PinVerticalIcon from '@/components/ui/new-icons/PinVertical';
import PinIcon from '@/components/ui/new-icons/Pin';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import { MEDIA_TYPE } from '@/components/ui/fields/ChatInputField/config';
import GifPlayer from '@/components/ui/GifPlayer';
import { useParams } from 'react-router-dom';
import useChatOnStage from './hooks/useChatOnStage';
import MessageFillRemove from '@/components/ui/new-icons/MessageFillRemove';
import MessageFillAdd from '@/components/ui/new-icons/MessageFillAdd';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectIsOrganizer } from '@/models/event';
import { keyboardAccessible } from '@/utils/keyboard accessible';
import { Smile } from '@styled-icons/boxicons-solid/Smile';
import {
  isOpenPickerAtom,
  messageIdAtom,
  openReactionsListPopupAtom,
} from './atoms';
import { useAtom, useSetAtom } from 'jotai';
import { Reaction, useReactionMutation } from '@/api/reactions';
import { selectCurrentUser } from '@/models/account';
import { useDispatch, useSelector } from 'react-redux';
import { CrossIcon } from '@/layouts/OrganizerChecklist/icons';
import { useChannelContext } from '@/services/video/channel-context/channel-context';
import { AttendeeChannelEvent } from '@/types/messaging';
import EmojiHandler from './EmojiHandler';
import { usePusherChannelState } from '@/services/messaging/pusher/pusher-channel-context';
import useReactionListener from './hooks/useReactionListener';
import { useUnmount } from 'react-use';
import { isEmpty } from 'lodash';
import { IEngagementEndpoint } from '@/components/ChannelSidePanel/types';

const ChatMessage = (props: IChatMessageProps) => {
  const {
    chatBoxType,
    message,
    showMessageDay,
    showMenu,
    pinDisabled = false,
    parent = IChatBoxParent.CHAT,
    isEmbedded = false,
    isRoom = false,
    showHybridChat = false,
    updateReactions,
    allowMessageReactions,
    engagementEndpoint,
    containerClassName,
    textClassName
  } = props;

  const {
    messageId,
    role: messageSenderRole,
    accountName,
    accountPicUrl,
    mediaContent,
    createdAt,
    mediaType,
    origin,
  } = message;

  const { eventId = '', stageId = '' } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const dispatch = useDispatch();
  const chatIdOnStage = useChatOnStage();
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const picUrl = prefixPicUrlIfNeeded(accountPicUrl);
  const messageOrigin = origin === 'VIRTUAL' ? 'Virtual' : 'In-person';

  const { hostChannel, attendeeChannel } = useChannelContext();

  const [isOpenPicker, setIsOpenPicker] = useAtom(isOpenPickerAtom);
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
  const [openReactionListPopup, setOpenReactionListPopup] = useAtom(
    openReactionsListPopupAtom,
  );
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [popupUsers, setPopupUsers] = useState<IPopupUser[]>([]);
  const setMessageId = useSetAtom(messageIdAtom);
  const currentUser = useSelector(selectCurrentUser);
  const { accountId: currentUserAccountId } = currentUser as any;
  const { eventAttendeeChannel } = usePusherChannelState();
  const {
    mutate: addEmojiReactions,
    isError: isReactionAddError,
  } = useReactionMutation.addEmojiReactions(messageId);

  const {
    mutate: removeEmojiReaction,
  } = useReactionMutation.removeEmojiReaction(messageId);

  const [reactionListData, setReactionsListData] = useState(message.reactions);
  const togglePicker = (media: string) => {
    setIsOpenPicker(media);
  };
  const pRef = useRef<HTMLParagraphElement>(null);

  const SenderLabel = (() => {
    const messengerRole = (messageSenderRole || '').toLowerCase();
    const textConfig = (() => {
      if (messengerRole === IChatMessageSender.SPEAKER) {
        return ['Speaker', ITextColors.NEUTRAL_DARK];
      } else if (messengerRole === IChatMessageSender.ORGANIZER) {
        return ['Organizer', ITextColors.SUCCESS];
      } else if (messengerRole === IChatMessageSender.MODERATOR) {
        return ['Moderator', ITextColors.SUCCESS];
      } else if ((showHybridChat || engagementEndpoint === IEngagementEndpoint.HYBRID) && messengerRole === IChatMessageSender.ATTENDEE) {
        return [messageOrigin, ITextColors.NEUTRAL_DARK];
      }
      return null;
    })();

    const Label = textConfig && (
      <>
        <Text
          text="• "
          textColor={ITextColors.NEUTRAL_MEDIUM}
          textSize={ITextSizes.XXSMALL}
          textStyle={ITextStyles.BOLDED}
          textLineHeight={1.2}
          block={false}
          className={styles.bulletIcon}
        />
        <Text
          text={textConfig[0]}
          textColor={textConfig[1]}
          textSize={ITextSizes.XXSMALL}
          textStyle={ITextStyles.BOLDED}
          textLineHeight={1.2}
          block={false}
        />
      </>
    );

    if (chatBoxType === IChatBoxTypes.PRIVATE) {
      if (isLiveEventOrganizer(messengerRole)) {
        return Label;
      }
    } else if (chatBoxType === IChatBoxTypes.PUBLIC) {
      if (
        messengerRole === IChatMessageSender.SPEAKER ||
        messengerRole === IChatMessageSender.ATTENDEE ||
        isLiveEventOrganizer(messengerRole.toUpperCase())
      ) {
        return Label;
      }
    } else if (chatBoxType === IChatBoxTypes.ATTENDEE) {
      if (
        messengerRole === IChatMessageSender.SPEAKER ||
        isLiveEventOrganizer(messengerRole.toUpperCase())
      ) {
        return Label;
      }
    }
    return null;
  })();

  const formatName = () => {
    if (SenderLabel == null) {
      return `${accountName.substring(0, Math.min(accountName.length, 30))}${
        accountName.length > 30 ? '...' : ''
      }`;
    }
    return `${accountName.substring(0, Math.min(accountName.length, 17))}${
      accountName.length > 17 ? '...' : ''
    }`;
  };

  const formatMessageTime = createdAt => {
    const temp = getMessageTime(createdAt).split(' - ');
    return temp[temp.length - 1];
  };

  const MessageTime = (
    <Text
      text={formatMessageTime(createdAt)}
      textColor={ITextColors.NEUTRAL}
      textSize={ITextSizes.XXSMALL}
      block={false}
    />
  );

  const SenderName = (
    <Text
      text={formatName()}
      textColor={ITextColors.MONOCHROME_CONTRAST}
      textSize={ITextSizes.XXSMALL}
      textStyle={ITextStyles.BOLDED}
      className={styles.name}
      textLineHeight={1.2}
      block={false}
    />
  );

  const getOrdinalDisplay = number => {
    const parsedDay = parseInt(number);
    const b = parsedDay % 10;
    const output =
      ~~((parsedDay % 100) / 10) === 1
        ? 'th'
        : b === 1
        ? 'st'
        : b === 2
        ? 'nd'
        : b === 3
        ? 'rd'
        : 'th';
    return (
      <>
        {parsedDay}
        <sup>{output}</sup>
      </>
    );
  };

  const formatDayTime = (() => {
    if (!showMessageDay) return null;
    const splitWords = showMessageDay.split(' ');
    if (splitWords.length > 1) {
      return (
        <div className={styles.messageDayBig}>
          <div className={styles.messageDayText}>
            {splitWords[0]}, {getOrdinalDisplay(splitWords[1])} {splitWords[2]}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.messageDay}>
        <div className={styles.messageDayText}>{showMessageDay}</div>
      </div>
    );
  })();

  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const dropdownContainerRef = useRef();
  const mainContainerRef = useRef();
  const handleClickOutside = () => {
    setShowOptionDropdown(false);
  };
  useEffect(() => {
    const reactions = message.reactions || [];
    setReactionsListData(reactions);
    setShowOptionDropdown(false);
  }, [message]);

  const handleClick = e => {
    if (!dropdownContainerRef || !dropdownContainerRef.current) {
      return;
    }
    if (dropdownContainerRef?.current?.contains(e.target)) {
      return;
    }
    handleClickOutside();
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  const chatPinStatusUpdate = () => {
    api.message.updateMessagePinStatus(message.messageId, !message.pinned);
  };

  const deleteChat = () => {
    api.message.deleteMessage(message.messageId);
  };

  const dropdownOptions = [];
  const hasMsgCharLimitReachedForPinning =
    mediaContent?.length > PIN_MESSAGE_LEN_LIMIT;

  const isPinnedOnStage = chatIdOnStage === message.messageId;

  const toggleShowOnStage = () => {
    if (isPinnedOnStage) {
      api.message.unpinFromStage(message.messageId, stageId);
    } else {
      api.message.pinOnStage(message.messageId, stageId);
    }
  };

  if (mediaType !== MEDIA_TYPE.gif) {
    if (isEmbedded && isOrganizer) {
      dropdownOptions.push({
        label: isPinnedOnStage ? 'Remove from stage' : 'Show on stage',
        onClick: toggleShowOnStage,
        icon: isPinnedOnStage ? (
          <MessageFillRemove
            size={12}
            color={getCssVar(IContentColors.MONOCHROME_CONTRAST)}
          />
        ) : (
          <MessageFillAdd
            size={12}
            color={getCssVar(IContentColors.MONOCHROME_CONTRAST)}
          />
        ),
        isDisabled: false,
      });
    }
    dropdownOptions.push({
      label: `${message.pinned ? 'Unpin Message' : 'Pin Message'}`,
      onClick: chatPinStatusUpdate,
      icon: (
        <PinVerticalIcon
          size={14}
          color={
            !message.pinned && pinDisabled
              ? IContentColors.NEUTRAL_MID_3
              : IContentColors.MONOCHROME_CONTRAST
          }
        />
      ),
      isDisabled:
        (!message.pinned && pinDisabled) ||
        (!message.pinned && hasMsgCharLimitReachedForPinning),
    });
  }
  dropdownOptions.push({
    label: 'Delete',
    onClick: deleteChat,
    icon: (
      <Trash
        size={10}
        fill={getCssVar(IContentColors.WARNING)}
        color={getCssVar(IContentColors.WARNING)}
      />
    ),
    isDisabled: false,
  });

  const handleReactionListPopup = () => {
    setOpenReactionListPopup(!openReactionListPopup);
    setMessageId(messageId);
  };

  const handleEmojiHover = (emoji: string) => {
    const users = reactionListData[emoji]?.map(reaction => ({
      firstName: reaction.fN,
      accountId: reaction.aId,
    }));

    setPopupUsers(users);
    setSelectedEmoji(emoji);
  };

  const handleEmojiLeave = () => {
    setPopupUsers([]);
    setSelectedEmoji(null);
  };

  const handleClosePicker = () => {
    setIsOpenPicker('');
  };

  let reactionTimer: ReturnType<typeof setTimeout>;
  const handleTouchStart = () => {
    reactionTimer = setTimeout(() => {
      setIsOpenPicker(mediaContent);
    }, 500);
  };

  const handleTouchEnd = () => {
    clearTimeout(reactionTimer);
  };

  const populateReactionCountsForMessageId = (reactionsData: {
    [key: string]: Reaction[];
  }) => {
    if (!reactionsData) {
      return null;
    }

    const reactionsArray = Object.entries(reactionsData);

    reactionsArray.sort(
      ([, reactionsA], [, reactionsB]) => reactionsB.length - reactionsA.length,
    );

    // Filter out reactions with count 0
    const filteredReactionsArray = reactionsArray.filter(
      ([, reactions]) => reactions.length > 0,
    );

    const sortedReactionCounts = filteredReactionsArray.reduce(
      (acc, [emoji, reactions]) => {
        acc[emoji] = reactions.length;
        return acc;
      },
      {},
    );
    return sortedReactionCounts;
  };

  const reactionWithCounts = useMemo(
    () => populateReactionCountsForMessageId(reactionListData),
    [reactionListData],
  );

  const handleEmojiTab = emoji => {
    const currentUserReaction = reactionListData[emoji].find(
      reaction => reaction.aId === currentUserAccountId,
    );
    const emojiData = reactionListData[emoji].find(
      reaction => reaction.eH === emoji,
    );
    if (currentUserReaction) {
      // Remove the existing reaction
      const currentUserReactionIndex = reactionListData[emoji].findIndex(
        reaction => reaction.aId === currentUserAccountId,
      );
      if (currentUserReactionIndex !== -1) {
        const updatedReactionsListData = {
          ...reactionListData,
          [emoji]: reactionListData[emoji].filter(
            (_, index) => index !== currentUserReactionIndex,
          ),
        };
        setReactionsListData(updatedReactionsListData);
      }
      removeEmojiReaction(currentUserReaction.id);
    } else {
      // Add the new reaction
      const updatedReactionsListData = {
        ...reactionListData,
        [emojiData.eH]: [
          ...(reactionListData[emojiData.eH] || []),
          {
            id: 'opt_reaction_id',
            eU: emojiData.eU,
            eH: emojiData.eH,
            aId: currentUserAccountId,
            fN: 'Current User First Name',
            lN: 'Current User Last Name',
            pU: null,
          },
        ],
      };
      setReactionsListData(updatedReactionsListData);
      const payload = {
        eventId: eventId,
        accountId: currentUserAccountId,
        emojiUnicode: emojiData.eU,
        emojiHtml: emojiData.eH,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        picUrl: currentUser.picUrl,
      };
      addEmojiReactions(payload);
    }
  };

  useEffect(() => {
    if (!isReactionAddError) return;

    const modifiedReactionListData = { ...reactionListData };
    // Iterate over the emojis in the modified reaction list data
    Object.entries(modifiedReactionListData).forEach(
      ([emoji, emojiReactionList]: any) => {
        // Find the index of the reaction to be removed
        const indexToRemove = emojiReactionList.findIndex(
          reaction =>
            reaction.id === 'opt_reaction_id' &&
            reaction.aId === currentUserAccountId,
        );

        if (indexToRemove !== -1) {
          modifiedReactionListData[emoji] = emojiReactionList.filter(
            (_, idx) => idx !== indexToRemove,
          );
        }

        // If the filtered list is empty, delete the key
        if (modifiedReactionListData[emoji].length === 0) {
          delete modifiedReactionListData[emoji];
        }
      },
    );

    setReactionsListData(modifiedReactionListData);

    dispatch({
      type: 'global/addDangerToast',
      payload: { description: 'Failed to react' },
    });
  }, [currentUserAccountId, isReactionAddError]);

  useReactionListener({
    channel: eventAttendeeChannel,
    messageId,
    currentUserAccountId,
    setReactionsListData,
    pusherEvent: AttendeeChannelEvent.CHAT_REACTION,
    updateReactions,
    reactionsListData: reactionListData,
    currentMessageId: messageId,
  });

  useReactionListener({
    channel: hostChannel,
    messageId,
    currentUserAccountId,
    setReactionsListData,
    pusherEvent: AttendeeChannelEvent.CHAT_REACTION,
    updateReactions,
    reactionsListData: reactionListData,
    currentMessageId: messageId,
  });

  useReactionListener({
    channel: attendeeChannel,
    messageId,
    currentUserAccountId,
    setReactionsListData,
    pusherEvent: AttendeeChannelEvent.CHAT_REACTION,
    updateReactions,
    reactionsListData: reactionListData,
    currentMessageId: messageId,
  });

  useReactionListener({
    channel: eventAttendeeChannel,
    messageId,
    currentUserAccountId,
    setReactionsListData,
    updateReactions,
    pusherEvent: AttendeeChannelEvent.CHAT_PIN_STATUS_UPDATE,
  });

  useReactionListener({
    channel: attendeeChannel,
    messageId,
    currentUserAccountId,
    setReactionsListData,
    updateReactions,
    pusherEvent: AttendeeChannelEvent.CHAT_PIN_STATUS_UPDATE,
  });

  useUnmount(() => {
    setIsOpenPicker('');
    setOpenReactionListPopup(false);
  });

  useEffect(() => {
    if (pRef.current) {
      const lineHeight = parseInt(
        getComputedStyle(pRef.current).lineHeight,
        10,
      );
      const height = pRef.current.offsetHeight;
      const lines = Math.ceil(height / lineHeight);

      const hoverPopElement = pRef.current.closest(
        `.${styles.hoverPop}`,
      ) as HTMLElement;
      if (hoverPopElement) {
        hoverPopElement.style.setProperty('--line-height', `${lineHeight}px`);
        hoverPopElement.style.setProperty('--line-count', `${lines}`);

        hoverPopElement.classList.toggle(styles.oneLineContent, lines === 1);
        hoverPopElement.classList.toggle(styles.multiLineContent, lines > 1);
      }
    }
  }, [popupUsers]);

  return (
    <React.Fragment>
      {showMessageDay && (
        <div tabIndex={0} className={styles.messageDayContainer}>
          {formatDayTime}
        </div>
      )}
      <div
        className={classnames(styles.container, containerClassName, {
          [styles.containerInRoom]: isRoom,
        })}
        data-private
        ref={mainContainerRef}
        onMouseLeave={() => setShowOptionDropdown(false)}
        tabIndex={0}
        aria-label={`message by ${SenderName?.props.text} at ${MessageTime?.props.text}`}
      >
        <div className={styles.pictureWrapper}>
          <ProfilePicture
            imageUrl={getImageUrlWithSize(picUrl, 20, 20, 'clip')}
            name={accountName}
            styleClass="chatProfilePic"
            hexColor={null}
            disableTooltip={true}
            iconSize={IGeneralSizeTypes.XXSMALL}
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentBody}>
            <div
              className={classnames(styles.header, {
                [styles.attendeeHeader]: !showMenu,
              })}
            >
              <div className={styles.about}>
                <div className={styles.senderName}>{SenderName} </div>
                <div tabIndex={0} className={styles.senderLabel}>
                  {SenderLabel}{' '}
                </div>
                {message.pinned && parent !== IChatBoxParent.PINNED_MESSAGE && (
                  <div
                    tabIndex={0}
                    aria-label="this message is pinned"
                    className={styles.pinnedContainer}
                  >
                    <PinIcon
                      size={10}
                      color={IContentColors.MONOCHROME_CONTRAST}
                    />
                  </div>
                )}
              </div>
              <Text
                text="• "
                textColor={ITextColors.NEUTRAL_MEDIUM}
                textSize={ITextSizes.XXSMALL}
                textStyle={ITextStyles.BOLDED}
                textLineHeight={1.2}
                block={false}
                className={styles.bulletIcon}
              />
              <div className={styles.time}>{MessageTime}</div>
            </div>
            <div
              tabIndex={0}
              aria-label={mediaType === MEDIA_TYPE.gif ? 'GIF' : mediaContent}
              className={classnames(styles.content, {
                [styles.contentClickable]: allowMessageReactions,
              })}
              onMouseEnter={() => setShowEmojiPicker(true)}
              onMouseLeave={() => setShowEmojiPicker(false)}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div
                aria-hidden
                className={styles.textWrapper}
                onClick={
                  allowMessageReactions && !isEmpty(reactionListData)
                    ? handleReactionListPopup
                    : undefined
                }
              >
                {mediaType === MEDIA_TYPE.gif ? (
                  <GifPlayer gifId={mediaContent} />
                ) : (
                  <Text
                    text={mediaContent}
                    textColor={ITextColors.MONOCHROME_CONTRAST}
                    textSize={ITextSizes.XXSMALL}
                    textMargin={ITextMargins.XSMALL_MARGIN_TOP}
                    textLineHeight="15px"
                    linkify
                    linkColorSameAsText
                    collapsible
                    collapseTextLength={200}
                    underlineLinks
                    className={textClassName}
                  />
                )}
              </div>
              {allowMessageReactions && showEmojiPicker && (
                <div className={styles.reactionEmoji}>
                  <Smile onClick={() => togglePicker(mediaContent)} />
                </div>
              )}
            </div>
            {allowMessageReactions && !!reactionWithCounts && (
              <div className={styles.emojiSelected}>
                <>
                  {Object.keys(reactionWithCounts)?.map(emoji => (
                    <div
                      aria-hidden
                      className={classnames(styles.countTab, {
                        [styles.currentUserTab]: reactionListData[emoji]?.some(
                          reaction => reaction.aId === currentUserAccountId,
                        ),
                        [styles.pinnedMessage]:
                          message.pinned &&
                          !reactionListData[emoji]?.some(
                            reaction => reaction.aId === currentUserAccountId,
                          ),
                      })}
                      onMouseEnter={() => handleEmojiHover(emoji)}
                      onMouseLeave={handleEmojiLeave}
                      onClick={() => handleEmojiTab(emoji)}
                    >
                      {emoji}
                      <span>
                        {reactionWithCounts[emoji] && reactionWithCounts[emoji]}
                      </span>
                    </div>
                  ))}
                  {popupUsers?.length > 0 && (
                    <div
                      className={classnames(styles.hoverPop, {
                        [styles.pinnedMessage]: message.pinned,
                      })}
                      onMouseEnter={() => handleEmojiHover(selectedEmoji!)}
                      onMouseLeave={handleEmojiLeave}
                    >
                      <div className={styles.emojiIcon}>{selectedEmoji}</div>
                      <p ref={pRef}>
                        {popupUsers?.length > 7 ? (
                          <>
                            {popupUsers
                              .slice(0, 7)
                              ?.map(user =>
                                user.accountId === currentUserAccountId
                                  ? 'you'
                                  : user.firstName,
                              )
                              .join(', ')}{' '}
                            &{' '}
                            <a href="#" onClick={handleReactionListPopup}>
                              {popupUsers?.length - 7} others
                            </a>
                          </>
                        ) : (
                          popupUsers
                            ?.map(user =>
                              user.accountId === currentUserAccountId
                                ? 'you'
                                : user.firstName,
                            )
                            .join(', ')
                        )}
                      </p>
                    </div>
                  )}
                </>
              </div>
            )}
          </div>
          {chatBoxType === IChatBoxTypes.PUBLIC && showMenu && (
            <>
              <div
                className={classnames(styles.menuContainer, {
                  [styles.mobileMenuContainer]: isMobile,
                })}
              >
                <div className={styles.menuDropdown}>
                  <div
                    className={styles.ctaWrapper}
                    onClick={() => setShowOptionDropdown(true)}
                    tabIndex={0}
                    aria-label="click to open dropdown"
                    onKeyDown={(e: any) =>
                      keyboardAccessible(e, () => {
                        setShowOptionDropdown(true);
                      })
                    }
                  >
                    <MoreVertical
                      size={16}
                      color={getCssVar(IContentColors.NEUTRAL_MID_2)}
                    />
                  </div>
                  {showOptionDropdown && (
                    <Dropdown
                      dropdownRef={dropdownContainerRef}
                      options={dropdownOptions}
                      onClick={handleClickOutside}
                      hasMsgCharLimitReachedForPinning={
                        hasMsgCharLimitReachedForPinning
                      }
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isOpenPicker === mediaContent && (
        <div className={styles.emojiPicker}>
          <div className={styles.popupContainer}>
            <div className={styles.popupHeader}>
              <span className={styles.popupTitle}>Reactions</span>
              <div onClick={handleClosePicker}>
                <CrossIcon />
              </div>
            </div>
          </div>
          <EmojiHandler
            eventId={eventId}
            addEmojiReactions={addEmojiReactions}
            reactionsListData={reactionListData}
            setReactionsListData={setReactionsListData}
            handleClosePicker={handleClosePicker}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export { IChatMessageSender };

export default ChatMessage;
